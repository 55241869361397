<template>
  <v-container fluid class="mt-5 mb-2">
    <alert-notification :message="alert" />
    <v-banner color="secondary" icon="mdi-lightbulb-on" dark outlined rounded class="mb-6 ma-auto" style="max-width: 500px;">
      <div>
        <h1>{{ $t('distribution.banner.title') }}</h1>
        <br>
        <ul>
          <li v-for="content in $t('distribution.banner.lines')" :key="content">
            {{ content }}
          </li>
        </ul>
      </div>
    </v-banner>
    <v-row justify="center" align="center" dense>
      <v-col cols="10" lg="4">
        <v-autocomplete v-model="areaId" :items="areas" item-value="id" item-text="name" :loading="loading" :disabled="loading" :label="$t('distribution.city')"
                        dense chips filled rounded solo clearable style="text-transform:uppercase;" @change="refreshZones"
        />
      </v-col>
      <v-col v-if="districts.length > 0" cols="10" lg="4">
        <v-select v-model="search" :items="districts" :loading="loading" :disabled="loading" :label="$t('distribution.district')"
                  dense chips filled rounded solo clearable style="text-transform:capitalize;"
        />
      </v-col>
    </v-row>
    <div style="max-width:1000px;" class="ma-auto">
      <v-card class="ma-0">
        <v-card-title>
          {{ $t('distribution.table.title') }}
          <v-spacer />
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('distribution.table.search')" single-line hide-details />
        </v-card-title>
        <v-data-table :headers="headers" :options="options" :items="zones" :loading="loading" :search="search"
                      :hide-default-header="false" :hide-default-footer="true" dense
        >
          <template #[`item.direction`]="{ item }">
            <v-btn color="secondary" class="white--text" small :href="gpsLink('google_maps', item)" target="_blank">
              Google
              <v-icon right dark>
                mdi-google-maps
              </v-icon>
            </v-btn>
            <v-btn color="secondary" class="ma-2 white--text" small :href="gpsLink('waze', item)" target="_blank">
              Waze
              <v-icon right dark>
                mdi-waze
              </v-icon>
            </v-btn>
          </template>
          <template #[`item.accessType`]="{ item }">
            <v-select v-model="item.accessType" :items="accessTypes" item-value="id" item-text="name" dense solo hide-details style="margin:0 auto;max-width:120px"
                      @change="currentZone=item,openAccessTypeDialog=true"
            />
          </template>
          <template #[`item.action`]="{ item }">
            <v-btn small rounded color="primary" :loading="loading" @click.stop="currentZone=item,openZoneValidationDialog=true">
              <v-icon medium dark>
                mdi-check
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-dialog v-model="openAccessTypeDialog" max-width="500" :fullscreen="true">
      <v-card>
        <v-toolbar dense color="primary">
          <v-btn icon dark @click.stop="updateAccessType('undo'),openAccessTypeDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="text-h3 text-break">
          {{ $t('distribution.table.confirm.access.title', {name: currentZone === undefined ? '' : currentZone.name}) }}
        </v-card-title>
        <v-card-text><br></v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click.stop="updateAccessType('undo'),openAccessTypeDialog=false">
            {{ $t('distribution.table.confirm.access.no') }}
          </v-btn>
          <v-spacer />
          <v-btn color="accent" @click.stop="updateAccessType('do'),openAccessTypeDialog=false">
            {{ $t('distribution.table.confirm.access.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openZoneValidationDialog" max-width="500" :fullscreen="true">
      <v-card>
        <v-toolbar dense color="primary">
          <v-btn icon dark @click.stop="openZoneValidationDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="text-h3 text-break">
          {{ $t('distribution.table.confirm.validation.title', {name: currentZone === undefined ? '' : currentZone.name}) }}
        </v-card-title>
        <v-card-text v-if="currentZone !== undefined && currentZone.accessType === 'unknown'" class="text-center">
          <v-row align="center" justify="center" class="ma-auto" style="max-width: 500px">
            <v-col>{{ $t('distribution.table.accessType') }}</v-col>
            <v-col>
              <v-select :value="undefined" :items="accessTypes" item-value="id" item-text="name" dense solo hide-details class="ma-auto" style="max-width:120px"
                        @change="setAccessType"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click.stop="openZoneValidationDialog=false,currentZone=undefined">
            {{ $t('distribution.table.confirm.validation.no') }}
          </v-btn>
          <v-spacer />
          <v-btn color="accent" @click.stop="createDistributionTour(),openZoneValidationDialog=false">
            {{ $t('distribution.table.confirm.validation.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { Logger } from '@/services/common/Logger';
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import DistributionAccessType from '@/services/distribution/DistributionAccessType';

  export default {
    name: 'Distribution',
    components: { AlertNotification },
    data () {
      return {
        alert: '',
        loading: false,
        search: '',
        areaId: undefined,
        areas: [],
        districts: [],
        zones: [],
        headers: [
          {
            text: this.$i18n.t('distribution.table.direction'),
            align: 'start',
            filterable: false,
            sortable: false,
            value: 'direction'
          },
          {
            text: this.$i18n.t('distribution.table.district'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'district'
          },
          {
            text: this.$i18n.t('distribution.table.name'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'name'
          },
          {
            text: this.$i18n.t('distribution.table.accessType'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'accessType'
          },
          {
            text: this.$i18n.t('distribution.table.action'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'action'
          }
        ],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        openZoneValidationDialog: false,
        currentZone: undefined,
        openAccessTypeDialog: false,
        accessTypes: [
          { id: DistributionAccessType.unknown, name: 'Inconnu' },
          { id: DistributionAccessType.street, name: 'sur rue' },
          { id: DistributionAccessType.vigik, name: 'Vigik' }
        ]
      };
    },
    mounted: async function () {
      await this.refreshAreas();
    },
    methods: {
      gpsLink: function (appName, zone) {
        const addresses = [zone.name];
        const area = this.getCurrentArea();
        const city = (area === undefined ? '' : ' - ' + area.name + ' - ' + area.code);
        if (appName === 'google_maps') {
          return 'https://maps.google.com/?daddr=' + encodeURIComponent(addresses.join(' & ') + city + ' - France');
        }
        if (appName === 'waze') {
          return 'https://waze.com/ul?q=' + encodeURIComponent(addresses.join(' & ') + city + ' - France');
        }

        return '';
      },
      getCurrentArea: function () {
        let area;
        let i = 0;
        while (area === undefined && i < this.areas.length) {
          if (this.areas[i].id === this.areaId) {
            area = this.areas[i];
          }
          i++;
        }

        return area;
      },
      async setAccessType (accessType) {
        if (this.currentZone === undefined) {
          Logger.exception(new Error('set access type: current zone is undefined'));
          return;
        }

        this.loading = true;
        try {
          await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'distribution/zone/accessType', {
            zoneId: this.currentZone.id,
            accessType: accessType
          });
          this.success();
        } catch (error) {
          await this.fail(error, this.$i18n.t('distribution.error'));
        }
        this.loading = false;
        await this.refreshZones();
      },
      async updateAccessType (action) {
        if (this.currentZone === undefined) {
          Logger.exception(new Error('update access type: current zone is undefined'));
          return;
        }

        if (action === 'do') {
          this.loading = true;
          try {
            await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'distribution/zone/accessType', {
              zoneId: this.currentZone.id,
              accessType: this.currentZone.accessType
            });
            this.success();
          } catch (error) {
            await this.fail(error, this.$i18n.t('distribution.error'));
          }
          this.loading = false;
        } else {
          this.currentZone.accessType = DistributionAccessType.unknown;
        }
        this.currentZone = undefined;
      },
      async createDistributionTour () {
        if (this.currentZone === undefined) {
          Logger.exception(new Error('tour: current zone is undefined'));
          return;
        }

        this.loading = true;
        try {
          await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'distribution/tour/', {
            type: 'mailbox',
            zoneId: this.currentZone.id
          });
          this.success();
        } catch (error) {
          await this.fail(error, this.$i18n.t('distribution.error'));
        }
        this.loading = false;
        await this.refreshZones();
        this.currentZone = undefined;
      },
      async refreshAreas () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'distribution/areas');
          if (response.data !== undefined && response.data.areas !== undefined) {
            this.areas = response.data.areas;
          }
          this.success();
        } catch (error) {
          await this.fail(error, this.$i18n.t('distribution.error'));
        }
        this.loading = false;
      },
      async refreshZones () {
        if (this.areaId === undefined || this.areaId === null) {
          this.zones = [];
          return;
        }

        this.loading = true;
        this.districts = [];
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'distribution/zones?areaId=' + this.areaId);
          if (response.data !== undefined && response.data.zones !== undefined) {
            this.zones = response.data.zones;
            this.zones.forEach((zone) => {
              if (zone.district !== null && zone.district !== undefined && !this.districts.includes(zone.district)) {
                this.districts.push(zone.district);
              }
            });
            this.districts.sort();
          }
          this.success();
        } catch (error) {
          await this.fail(error, this.$i18n.t('distribution.error'));
        }
        this.loading = false;
      },
      success () {
        this.alert = '';
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
        this.loading = false;
      }
    }
  };
</script>
